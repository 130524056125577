<template>
  <div style="padding-left: 20px">
    <!--<page-header :title="'Invitation'" />
    <div>
      <p>
        Thank you for accepting invite. Please login to access the accounts.
      </p>
    </div>-->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
//import PageHeader from "@/components/PageHeader";

export default {
  name: "acceptInvite",
  components: {
    //PageHeader
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  data() {
    return {
      isReceiving: false,
      email: ""
    };
  },

  computed: {
    ...mapState("users", {
      isLoading: state => state.isLoading,
      isValid: state => state.validRequest,
      isUserInvited: state => state.isUserInvited
    })
  },

  async mounted() {
    this.isReceiving = true;
    if (!this.isUserInvited) {
      this.email = this.$route.query.email
        .replace(/ /g, "+")
        .replace(/%40/gi, "@")
        .replace(/%3A/gi, ":")
        .replace(/%24/g, "$")
        .replace(/%2C/gi, ",")
        .replace(/%3B/gi, ";")
        .replace(/%20/g, "+");
      await this.validateEmailAndToken(
        this.email,
        this.$route.query.token,
        "INVITATION"
      );
      if (this.isValid) {
        await this.mapInvitesToUserAccountMaps(
          this.email,
          this.$route.query.token
        );
        this.$message({
          type: "success",

          // eslint-disable-next-line
          message: __("Thank you for accepting invite. Please login to access the accounts.")
        });
        this.isReceiving = false;
        this.$router.push({ path: "/login" }).catch(() => {});
      } else {
        this.isReceiving = false;
        this.$router.push({ path: "/login" }).catch(() => {});
      }
    } else {
      this.$router.push({ path: "/login" }).catch(() => {});
    }
  },

  async created() {},

  methods: {
    ...mapActions("users", {
      validateProfileRequest: "validateProfileRequest",
      acceptInviteRequest: "acceptInviteRequest"
    }),

    async validateEmailAndToken(email, token, type) {
      let obj = { email, token, type };
      await this.validateProfileRequest(obj);
    },

    async mapInvitesToUserAccountMaps(email, token) {
      let obj = { email, token };
      await this.acceptInviteRequest(obj);
    }
  }
};
</script>

<style scoped></style>
